export const getTime = (date: string | undefined | null): number | null => {
  if (!date) {
    return null
  }

  const parsedTime = new Date(date).getTime()
  return isNaN(parsedTime) ? null : parsedTime
}

export const checkMarketingTickerVisibility = (): boolean => {
  const renderFromTime = getTime(process.env.MARKETING_TICKER_FROM)
  const renderTillTime = getTime(process.env.MARKETING_TICKER_TILL)

  if (!renderFromTime || !renderTillTime) {
    return false
  }

  const currentTime = new Date().getTime()
  return currentTime >= renderFromTime && currentTime <= renderTillTime
}
