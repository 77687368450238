import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'
import { Helmet } from 'react-helmet'

import styles from './styles.module.scss'

interface QuestionAnswer {
  question: string
  answer: string
}

const items: QuestionAnswer[] = [
  {
    question: 'What is a font generator?',
    answer: '<p>A font generator is a tool which allows you to change the way text is formatted. Font Maker is a free font generator that offers a wide range of text styles and symbols which can be applied almost anywhere online, including social media posts and bios, emails, browser bookmakers, and more.</p>'
  },
  {
    question: 'How do I use a font generator?',
    answer: '<p>To use Font Maker, find the text input field at the top of the page. Type in your text, then scroll down to view the font options available. Once you’ve found your preferred font, click on the copy and paste icon. The text will be automatically copied to your clipboard so you can paste it in wherever you’d like.</p>'
  },
  {
    question: 'How does a web font generator work?',
    answer: '<p>A web font generator uses Unicode characters to replace traditional characters. As you type in your text, Font Maker automatically finds the best Unicode match for each letter or symbol. Because there are thousands of Unicode characters, you can create a wide variety of unique, eye-catching fonts by using this font generator.</p>'
  },
  {
    question: 'What is a Unicode font?',
    answer: '<p>Unicode is a system which ensures that characters appear the same across any program or platform.</p><p>Computers only use binary code to store and send data, which means that all characters are actually made through a series of 1s and 0s.</p><p>Unicode is a practical agreement to use the same 1s and 0s to represent each letter, symbol, or emoji.</p><p>k     =    00000000000000000000000001101011</p><p>W     =    00000000000000000000000001010111</p><p>👌    =    11110000100111111001000110001100</p><p>Unicode is a dictionary of all these numbers and to which characters they correspond. There are currently over 137,000 characters which can be used to create distinctive fonts.</p>'
  },
  {
    question: 'Where can I use these fonts?',
    answer: '<p>You can use characters generated with Font Maker pretty much everywhere online, including social media posts, bios, and comments on sites like Facebook, X, Reddit, and Instagram. They can also be included in emails, instant messages, browser bookmarks, Wi-Fi networks, and lots more. They even show up in Google results.</p>'
  }
]

// https://developers.google.com/search/docs/appearance/structured-data/faqpage
const pageData = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: items.map(item => ({
    '@type': 'Question',
    name: item.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.answer
    }
  }))
})

interface Props {
  includePageData?: boolean
}

export const FAQ: React.FC<Props> = ({ includePageData = false }) => {
  return (
    <>
      {includePageData && (
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: pageData
            }
          ]}
        />
      )}
      <div className={styles.container}>
        <h5 className={styles.title}>FAQs</h5>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {items.map(item => (
            <AccordionItem key={item.question}>
              <AccordionItemHeading>
                <AccordionItemButton dangerouslySetInnerHTML={{ __html: item.question }} />
              </AccordionItemHeading>
              <AccordionItemPanel dangerouslySetInnerHTML={{ __html: item.answer }} />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </>
  )
}
