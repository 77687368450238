import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import cn from 'classnames'

import { Breadcrumps } from '../../components/Breadcrumbs'
import { MarketingTicker } from '../../components/ticker'
import { Discover } from '../../components/Discover'
import { FAQ } from '../../components/FAQ'
import { InfoWithImages } from '../../components/InfoWithImages'
import { Logo } from '../../components/Logo'
import { Title } from '../../components/Title'
import { WhyNamecheap } from '../../components/WhyNamecheap'

import { CopyAndPasteTab } from './CopyAndPaste'
import { FontGeneratorTab } from './FontGenerator'

import styles from './styles.module.scss'
import { checkMarketingTickerVisibility } from '../../helpers/marketingTickerHelpers'

const Home: React.FC = () => {
  const [tab, selectTab] = useState(1)
  const showMarketingTicker = useMemo(() => checkMarketingTickerVisibility(), [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Helmet>
        <title>Font Generator - Free Copy and Paste Text</title>
        <meta
          name="description"
          content={`
            Free font generator to copy and paste cool text to Instagram and Facebook.
            Turn any text into a fancy font to post to any of your social media profiles.
          `}
        />
      </Helmet>

      <header className={styles.header}>
        <div className={styles['header-image']} />
        {showMarketingTicker && <MarketingTicker />}
        <Breadcrumps />
        <div className={styles['small-container']}>
        <Logo className={styles.logo} />
        <Title size={1} h={1} white center>Free font generator</Title>
        <p className={styles.subtitle}>Transform your text with unique characters and symbols or copy and paste stunning, personalized fonts.</p>

        <div className={styles['tabs-container']}>
          <button onClick={() => selectTab(1)} className={cn(styles.tab, { [styles.active]: tab === 1 })}>Font generator</button>
          <button onClick={() => selectTab(2)} className={cn(styles.tab, styles['second-tab'], { [styles.active]: tab === 2 })}>Copy and paste</button>
        </div>
        </div>
      </header>

      <div className={styles['small-container']}>
        {tab === 1 ? <FontGeneratorTab /> : null}
        {tab === 2 ? <CopyAndPasteTab /> : null}
      </div>

      <InfoWithImages />
      <Discover />
      <WhyNamecheap />
      <FAQ includePageData />
    </div>
  )
}

export default Home
