import React from 'react'
import { Link } from 'react-router-dom'

import { Title } from '../Title'

import FirstImg from './assets/1.svg'
import SecondImg from './assets/2.svg'
import ThirdImg from './assets/3.svg'
import ForthImg from './assets/4.svg'

import styles from './styles.module.scss'

export const InfoWithImages: React.FC = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <Title center>How does this work?</Title>
        <div className={styles.row} style={{ marginTop: 48 }}>
          <div><FirstImg /></div>
          <p className={styles.text}>
            Font Maker replaces standard Unicode characters with non-standard ones to create unique
            fonts that can be used almost anywhere online. Typical uppercase and lowercase alphabet
            characters can be swapped with other styles, or you can copy and paste symbols and emojis
            to add personality to your text.
          </p>
        </div>
      </section>
      <section className={styles.section}>
        <Title center>About Font Maker</Title>
        <div className={styles['about-content']} style={{ marginTop: 48 }}>
          <div className={styles.row}>
            <div><SecondImg /></div>
            <div className={styles['text-column']}>
              <b className={styles.subtitle}>
                Create unique fonts
              </b>
              <p className={styles.text}>
                Quickly create eye-catching, <Link to='/fancy/'>fancy fonts</Link> text that works in social media posts, bios, messages, and more.
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['text-column']}>
              <b className={styles.subtitle}>
                Give yourself stunning options
              </b>
              <p className={styles.text}>
                Backwards font, symbols, horoscopes — you name it. There are nearly unlimited font options to give your text some personality.
              </p>
            </div>
            <div><ThirdImg /></div>
          </div>
          <div className={styles.row}>
            <div><ForthImg /></div>
            <div className={styles['text-column']}>
              <b className={styles.subtitle}>
                Type, copy, and paste
              </b>
              <p className={styles.text}>
              There’s no fee to use this tool, and no login is required. Simply type your text into the box, style it, and copy it into your chosen platform.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
