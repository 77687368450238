import React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

export const MarketingTicker: React.FC = () => {
  return (
        <div className={cn(styles.gbTicker)}>
            <a href="/domain-web-hosting-ssl-deals/black-friday/">
                Visit our Black Friday Temple of Deals to save up to 97% across our product range! →
            </a>
        </div>
  )
}
